import React, { useRef, useState, useEffect } from 'react'
import { Link } from 'gatsby'
import { StaticQuery, graphql } from 'gatsby'

const FooterAreasServedList = () => (
  <StaticQuery
    query={graphql`
      query FooterAreasServedListQuery {
        allContentfulServiceArea(sort: { fields: location, order: ASC }) {
          edges {
            node {
              id
              slug
              location
            }
          }
        }
      }
    `}
    render={(data) => {
      return (
        <div className="grid gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
          {data.allContentfulServiceArea.edges.map((item, i) => {
            return (
              <div className="pb-4" key={i}>
                <span className="font-semibold text-base text-black block">
                  <Link
                    className="text-sm  block"
                    to={`/pressure-washers-${item.node.slug}/`}
                    title={`Pressure-washing ${item.node.location}`}
                  >
                    {item.node.location}
                  </Link>
                </span>
              </div>
            )
          })}
        </div>
      )
    }}
  />
)

export default FooterAreasServedList
