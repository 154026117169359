import React, { useState } from 'react'
import { navigate } from 'gatsby'
import { setItemToLStore } from '../helpers/hash'
import InputMask from 'react-input-mask'
import { useLocalStorage } from '../hooks/useLocalStorage'
import Button from './atoms/Button'

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

function ContactForm({
  hideLabels,
  showPlaceholders,
  stacked,
  fontsize,
  footerContained,
  grayInputBg,
}) {
  const [offer, setOffer] = useLocalStorage('offers', {})

  const [formInputStates, setFormInputStates] = useState()
  const [submitInProgress, setSubmitInProgress] = useState(false)
  const [formSubmitErrors, setFormSubmitErrors] = useState([])

  function handleChange(e) {
    setFormInputStates({ ...formInputStates, [e.target.name]: e.target.value })
  }

  function handleSubmit(e) {
    setSubmitInProgress(true)
    e.preventDefault()

    if (!validateForm()) {
      window.scroll(0, 0)
      setSubmitInProgress(false)
      return
    }
    const form = e.target
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        subject:
          'Grime Busters -' +
          (formInputStates.Lastname +
            ', ' +
            formInputStates.Firstname +
            ' (' +
            new Date().toLocaleString() +
            ')'),
        'form-name': form.getAttribute('name'),
        ...formInputStates,
        landingURL:
          typeof window !== 'undefined' &&
          window.localStorage &&
          window.localStorage.getItem('lNdUrL')
            ? window.localStorage.getItem('lNdUrL')
            : 'Not Provided',
        currentPath:
          typeof window !== 'undefined' &&
          window.localStorage &&
          window.localStorage.getItem('crNtPaTH')
            ? window.localStorage.getItem('crNtPaTH')
            : 'Not Provided',
      }),
    })
      .then(() => {
        setItemToLStore('form', { date: new Date(), ...formInputStates })
        //after submit success, show spinner .05 sec
        setTimeout(() => navigate(form.getAttribute('action')), 500)
      })
      .catch((error) => {
        alert(error)
        setSubmitInProgress(false)
      })
  }

  function validateForm() {
    let sanitzePhone = formInputStates.phone.replace(/_/g, '')

    let formValidateSuccess = true
    let formErrorFirstName = false
    let formErrorLastName = false
    let formErrorEmail = false
    let formErrorDesc = false

    let listOfErrors = []

    if (
      formInputStates.Firstname.length < 2 ||
      !formInputStates.Firstname.replace(/\s/g, '').length
    ) {
      listOfErrors.push('First name is a required field.')
      formValidateSuccess = false
      formErrorFirstName = true
    }
    if (
      formInputStates.Lastname.length < 2 ||
      !formInputStates.Lastname.replace(/\s/g, '').length
    ) {
      listOfErrors.push('Last name is a required field.')
      formValidateSuccess = false
      formErrorLastName = true
    }

    if (sanitzePhone.length - 4 < 10) {
      listOfErrors.push('Please enter a complete phone number.')
      formValidateSuccess = false
    }
    if (
      !formInputStates.email.match(
        /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
      )
    ) {
      listOfErrors.push('Email is a required field.')
      formValidateSuccess = false
      formErrorEmail = true
    }
    if (
      formInputStates.description.length < 1 ||
      !formInputStates.description.replace(/\s/g, '').length
    ) {
      listOfErrors.push('Description cannot be left blank.')
      formValidateSuccess = false
      formErrorDesc = true
    }

    setFormSubmitErrors(listOfErrors)

    return formValidateSuccess
  }

  return (
    <form
      name="contact"
      method="post"
      action="/thanks/"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      onSubmit={handleSubmit}
    >
      {offer && Object.keys(offer).length > 0 ? (
        <div className="shadow-md bg-white border-l-4 border-brand-500 p-4 text-gray-700 rounded-2xl mb-6 flex space-x-4 ">
          <svg
            className="w-6 h-6 text-brand-500"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
          >
            <path
              fill="currentColor"
              d="M48 32H197.5C214.5 32 230.7 38.74 242.7 50.75L410.7 218.7C435.7 243.7 435.7 284.3 410.7 309.3L277.3 442.7C252.3 467.7 211.7 467.7 186.7 442.7L18.75 274.7C6.743 262.7 0 246.5 0 229.5V80C0 53.49 21.49 32 48 32L48 32zM112 176C129.7 176 144 161.7 144 144C144 126.3 129.7 112 112 112C94.33 112 80 126.3 80 144C80 161.7 94.33 176 112 176z"
            />
            <path
              fill="currentColor"
              className="opacity-50"
              d="M311.1 38.93C320.5 29.61 335.7 29.7 344.1 39.13L472.8 168.4C525.1 221.4 525.1 306.6 472.8 359.6L360.8 472.9C351.5 482.3 336.3 482.4 326.9 473.1C317.4 463.8 317.4 448.6 326.7 439.1L438.6 325.9C472.5 291.6 472.5 236.4 438.6 202.1L310.9 72.87C301.5 63.44 301.6 48.25 311.1 38.93V38.93z"
            />
          </svg>
          <div>
            <span className="font-bold font-display block mb-1">
              Claimed Offers
            </span>
            <span className="block  ">
              {offer ? offer && offer.offer : null}
            </span>
            <Button
              size="small"
              className=" -ml-2 -mb-2"
              color="secondary"
              type="button"
              onClick={() => setOffer(null)}
            >
              Remove
            </Button>
          </div>
        </div>
      ) : null}

      {formSubmitErrors.length > 0 ? (
        <div className="shadow rounded-2xl mb-8 bg-gray-50 p-4">
          <span className="flex mb-2 text-red-600 font-semibold">
            <svg
              className="w-6 h-6 "
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                clip-rule="evenodd"
              />
            </svg>{' '}
            There were some problems with your submission.
          </span>

          <ul className="p-0 m-0 list-none">
            {formSubmitErrors.map((error) => (
              <li className="block">- {error}</li>
            ))}
          </ul>
        </div>
      ) : null}

      {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
      <input type="hidden" name="form-name" value="contact" />
      <input type="hidden" name="landingURL" />
      <input type="hidden" name="currentPath" />
      <div hidden>
        <label>
          Don’t fill this out:{' '}
          <input name="bot-field" onChange={handleChange} />
        </label>
      </div>
      <div className="grid grid-cols-1 gap-y-3 gap-x-3 sm:grid-cols-6">
        <div className={`${stacked ? 'sm:col-span-6' : 'sm:col-span-3'}`}>
          <label
            className={`block text-sm font-medium leading-5 text-gray-700  mb-1 label ${
              hideLabels ? 'hidden' : ''
            } ${fontsize}`}
            htmlFor={'Firstname'}
          >
            First Name
          </label>
          <input
            className={`${
              grayInputBg ? 'bg-gray-50' : 'bg-white shadow-sm '
            } w-full input p-3  rounded-2xl border-gray-100 border appearance-none focus:outline-none focus:border-gray-400`}
            type={'text'}
            name={'Firstname'}
            onChange={handleChange}
            id={'Firstname'}
            required={true}
            placeholder={showPlaceholders ? 'First Name' : ''}
          />
        </div>

        <div className={`${stacked ? 'sm:col-span-6' : 'sm:col-span-3'}`}>
          <label
            className={`block text-sm font-medium leading-5 text-gray-700  mb-1 label ${
              hideLabels ? 'hidden' : ''
            } ${fontsize}`}
            htmlFor={'Lastname'}
          >
            Last Name
          </label>

          <input
            className={`${
              grayInputBg ? 'bg-gray-50' : 'bg-white shadow-sm '
            } w-full input p-3   rounded-2xl border-gray-100 border appearance-none focus:outline-none focus:border-gray-400`}
            type={'text'}
            name={'Lastname'}
            onChange={handleChange}
            id={'Lastname'}
            required={true}
            placeholder={showPlaceholders ? 'Last Name' : ''}
          />
        </div>
        <div className={`${stacked ? 'sm:col-span-6' : 'sm:col-span-3'}`}>
          <label
            className={`block text-sm font-medium leading-5 text-gray-700  mb-1 label ${
              hideLabels ? 'hidden' : ''
            } ${fontsize}`}
            htmlFor={'email'}
          >
            Email
          </label>

          <input
            className={`${
              grayInputBg ? 'bg-gray-50' : 'bg-white shadow-sm '
            } w-full input p-3   rounded-2xl border-gray-100 border appearance-none focus:outline-none focus:border-gray-400`}
            type={'email'}
            name={'email'}
            onChange={handleChange}
            id={'email'}
            required={true}
            placeholder={showPlaceholders ? 'Email' : ''}
          />
        </div>

        <div className={`${stacked ? 'sm:col-span-6' : 'sm:col-span-3'}`}>
          <label
            className={`block text-sm font-medium leading-5 text-gray-700  mb-1 label ${
              hideLabels ? 'hidden' : ''
            } ${fontsize}`}
            htmlFor={'phone'}
          >
            Phone
          </label>

          <InputMask
            placeholder={showPlaceholders ? 'Phone' : ''}
            className={`${
              grayInputBg ? 'bg-gray-50' : 'bg-white shadow-sm '
            } w-full input p-3  rounded-2xl border-gray-100 border appearance-none focus:outline-none focus:border-gray-400`}
            required={true}
            mask="(999) 999-9999"
            type={'phone'}
            name={'phone'}
            id={'phone'}
            onChange={handleChange}
            value={
              formInputStates && formInputStates.phone
                ? formInputStates.phone
                : null
            }
          />
        </div>
        <div className="sm:col-span-6">
          <label
            className={`block text-sm font-medium leading-5 text-gray-700  mb-1 label ${
              hideLabels ? 'hidden' : ''
            } ${fontsize}`}
            htmlFor={'description'}
          >
            Tell us about your project. Include address
          </label>

          <textarea
            className={`${
              grayInputBg ? 'bg-gray-50' : 'bg-white shadow-sm '
            } textarea w-full input p-3  rounded-2xl border-gray-100 border appearance-none focus:outline-none focus:border-gray-400`}
            name={'description'}
            rows={5}
            onChange={handleChange}
            id={'description'}
            required={true}
            placeholder={
              showPlaceholders
                ? 'Tell us about your project. Include address'
                : ''
            }
          />
        </div>
      </div>
      <div className="field">
        <button
          className="button-active-bhvr w-full mt-2 md:text-lg cursor-pointer transition duration-150 ease-in-out bg-brand-500 focus:bg-brand-600 hover:bg-brand-600 focus:outline-none font-semibold px-4 py-3 lg:py-3 lg:px-5 rounded-full text-white font-display flex items-center justify-center"
          type="submit"
        >
          Request FREE Estimate{' '}
          {submitInProgress ? (
            <svg
              className="animate-spin ml-3 h-5 w-5 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          ) : null}{' '}
        </button>
      </div>

      <div className={`my-3`}>
        <p className="text-gray-500 text-center text-sm flex items-center justify-center pb-2">
          {' '}
          <svg
            className="w-4 h-4 mr-1 text-gray-400"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
              clipRule="evenodd"
            />
          </svg>{' '}
          Your privacy is important to us. By submitting this form, you agree to
          receive text messages from our business about your project.
        </p>
      </div>
    </form>
  )
}

export default ContactForm
