import React from 'react'
import styled from 'styled-components'

const textColor = '#333'

const Logo = ({ classes }) => (

  <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className={`${classes ? classes : 'text-brand-500'}`} viewBox="0 0 335.46 254.76">
    <g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1">
      <path fill="currentColor" d="M43.76,155.1H40.88v11.52a28.6,28.6,0,0,1-7.4,2.56A46.6,46.6,0,0,1,23,170.3q-10.75,0-16.87-5.67T0,147.1Q0,135,6.07,129.42t17.36-5.52a43.49,43.49,0,0,1,10.25,1.15,26.61,26.61,0,0,1,7.2,2.59V141.4H26.49v-2.71a5,5,0,0,0-1-3.48,4.23,4.23,0,0,0-3.2-1.07,4.2,4.2,0,0,0-3.19,1.07,4.92,4.92,0,0,0-1,3.48v16.82a4.92,4.92,0,0,0,1,3.48,4.2,4.2,0,0,0,3.19,1.06,4.23,4.23,0,0,0,3.2-1.06,5,5,0,0,0,1-3.48v-.41H23.38v-8.58H43.76Z" /><path fill="currentColor" d="M44.22,160.05H47.1V134.14H44.22V124.7H70.65q7.42,0,11.34,3t3.91,9.91a13.62,13.62,0,0,1-1.55,6.68,8.33,8.33,0,0,1-4.43,3.91l0,.58q5.53.85,5.53,6.79v4.43h2.71v9.45H70V156a7,7,0,0,0-.67-3.4A3.33,3.33,0,0,0,67.22,151a15.71,15.71,0,0,0-4-.4v9.44H67v9.45H44.22Zm22.11-16.46a3.24,3.24,0,0,0,2.5-.9,3.94,3.94,0,0,0,.84-2.79v-2.07a3.94,3.94,0,0,0-.84-2.79,3.24,3.24,0,0,0-2.5-.9H63.22v9.45Z" /><path fill="currentColor" d="M89.13,169.5v-9.45H92V134.14H89.13V124.7H111v9.44h-2.88v25.91H111v9.45Z" />
      <path fill="currentColor" d="M175.67,134.14h-2.88v25.91h2.88v9.45H153.9v-9.45h2.77V139.9l-9.79,29.6h-8.52l-10.08-29.31v19.86H131v9.45H112.16v-9.45H115V134.14h-2.88V124.7h26.37l6,18,5.75-18h25.4Z" /><path fill="currentColor" d="M176.82,169.5v-9.45h2.88V134.14h-2.88V124.7h38.86v17.39h-12.9v-8h-7v9.1h5.06v6.56h-5.06v10.25h7V151h12.9V169.5Z" /><path fill="#231f20" d="M32.2,247.56A3.64,3.64,0,0,1,30.65,249a5.63,5.63,0,0,1-2.68.56H25.78v5H23.16V241.54H28a5.81,5.81,0,0,1,2.59.52,3.68,3.68,0,0,1,1.61,1.45,4.1,4.1,0,0,1,.53,2.08A4,4,0,0,1,32.2,247.56ZM29.48,247a1.83,1.83,0,0,0,.52-1.4c0-1.28-.71-1.91-2.14-1.91H25.78v3.8h2.08A2.31,2.31,0,0,0,29.48,247Z" /><path fill="#231f20" d="M48.81,254.63l-2.89-5.1H44.68v5.1H42.06V241.54H47a5.83,5.83,0,0,1,2.59.53,3.72,3.72,0,0,1,1.6,1.45,3.86,3.86,0,0,1-2.44,5.8l3.13,5.31Zm-4.13-7.07h2.2a2.16,2.16,0,0,0,1.59-.52,1.91,1.91,0,0,0,.53-1.43,1.84,1.84,0,0,0-.53-1.4,2.24,2.24,0,0,0-1.59-.5h-2.2Z" /><path fill="#231f20" d="M64.21,243.66v3.28h4.4V249h-4.4v3.47h5v2.14h-7.6V241.52h7.6v2.14Z" />
      <path fill="#231f20" d="M81.11,254.29a4,4,0,0,1-1.73-1.35,3.51,3.51,0,0,1-.66-2.08h2.81a1.79,1.79,0,0,0,.58,1.27,2,2,0,0,0,1.41.47,2.12,2.12,0,0,0,1.44-.44,1.43,1.43,0,0,0,.53-1.15,1.33,1.33,0,0,0-.36-1,2.47,2.47,0,0,0-.89-.59q-.52-.21-1.47-.48a14.82,14.82,0,0,1-2.07-.74,3.62,3.62,0,0,1-1.37-1.1,3.19,3.19,0,0,1-.57-2,3.53,3.53,0,0,1,.58-2A3.76,3.76,0,0,1,81,241.81a6,6,0,0,1,2.4-.46,5.18,5.18,0,0,1,3.29,1,3.62,3.62,0,0,1,1.4,2.74H85.17A1.45,1.45,0,0,0,84.6,244a2.14,2.14,0,0,0-1.42-.44,1.81,1.81,0,0,0-1.22.39,1.41,1.41,0,0,0-.46,1.14,1.18,1.18,0,0,0,.34.88,2.72,2.72,0,0,0,.87.56c.34.14.82.31,1.45.5a15.37,15.37,0,0,1,2.08.75,3.73,3.73,0,0,1,1.39,1.12,3.12,3.12,0,0,1,.58,2,3.72,3.72,0,0,1-.54,1.95,3.94,3.94,0,0,1-1.6,1.44,5.51,5.51,0,0,1-2.49.53A6.29,6.29,0,0,1,81.11,254.29Z" />
      <path fill="#231f20" d="M100,254.29a4,4,0,0,1-1.73-1.35,3.51,3.51,0,0,1-.66-2.08h2.82a1.74,1.74,0,0,0,.57,1.27,2,2,0,0,0,1.41.47,2.17,2.17,0,0,0,1.45-.44,1.42,1.42,0,0,0,.52-1.15,1.37,1.37,0,0,0-.35-1,2.61,2.61,0,0,0-.9-.59q-.52-.21-1.47-.48a14.82,14.82,0,0,1-2.07-.74,3.62,3.62,0,0,1-1.37-1.1,3.19,3.19,0,0,1-.57-2,3.53,3.53,0,0,1,.58-2,3.76,3.76,0,0,1,1.63-1.32,6,6,0,0,1,2.4-.46,5.18,5.18,0,0,1,3.29,1,3.62,3.62,0,0,1,1.4,2.74h-2.89a1.45,1.45,0,0,0-.57-1.11,2.13,2.13,0,0,0-1.41-.44,1.81,1.81,0,0,0-1.23.39,1.41,1.41,0,0,0-.46,1.14,1.18,1.18,0,0,0,.34.88,2.83,2.83,0,0,0,.87.56c.34.14.83.31,1.45.5a15.37,15.37,0,0,1,2.08.75,3.73,3.73,0,0,1,1.39,1.12,3.12,3.12,0,0,1,.58,2,3.72,3.72,0,0,1-.54,1.95,3.94,3.94,0,0,1-1.6,1.44,5.51,5.51,0,0,1-2.49.53A6.29,6.29,0,0,1,100,254.29Z" /><path fill="#231f20" d="M119.49,241.54v8.1a2.8,2.8,0,0,0,.69,2,3.09,3.09,0,0,0,3.92,0,2.8,2.8,0,0,0,.7-2v-8.1h2.64v8.08a5.21,5.21,0,0,1-.72,2.82,4.63,4.63,0,0,1-1.93,1.74,6.13,6.13,0,0,1-2.69.58,6,6,0,0,1-2.66-.58,4.44,4.44,0,0,1-1.88-1.74,5.38,5.38,0,0,1-.7-2.82v-8.08Z" />
      <path fill="#231f20" d="M144.22,254.63l-2.88-5.1H140.1v5.1h-2.63V241.54h4.92a5.79,5.79,0,0,1,2.58.53,3.74,3.74,0,0,1,1.61,1.45,4,4,0,0,1,.53,2,3.91,3.91,0,0,1-.75,2.34,3.84,3.84,0,0,1-2.23,1.43l3.13,5.31Zm-4.12-7.07h2.19a2.17,2.17,0,0,0,1.6-.52,1.9,1.9,0,0,0,.52-1.43,1.83,1.83,0,0,0-.52-1.4,2.26,2.26,0,0,0-1.6-.5H140.1Z" /><path fill="#231f20" d="M159.62,243.66v3.28H164V249h-4.41v3.47h5v2.14H157V241.52h7.6v2.14Z" /><path fill="#231f20" d="M203.93,241.54l-3.65,13.09h-3.1l-2.45-9.32-2.57,9.32-3.08,0-3.53-13.11h2.82l2.3,10.16,2.67-10.16h2.92l2.52,10.11,2.32-10.11Z" />
      <path fill="#231f20" d="M221.17,252.13H216l-.86,2.5h-2.76L217,241.52h3.06l4.7,13.11H222Zm-.72-2.1-1.89-5.47L216.67,250Z" /><path fill="#231f20" d="M236.12,254.29a4,4,0,0,1-1.73-1.35,3.51,3.51,0,0,1-.66-2.08h2.82a1.74,1.74,0,0,0,.57,1.27,2,2,0,0,0,1.41.47,2.17,2.17,0,0,0,1.45-.44,1.42,1.42,0,0,0,.52-1.15,1.37,1.37,0,0,0-.35-1,2.61,2.61,0,0,0-.9-.59q-.52-.21-1.47-.48a14.82,14.82,0,0,1-2.07-.74,3.62,3.62,0,0,1-1.37-1.1,3.19,3.19,0,0,1-.57-2,3.53,3.53,0,0,1,.58-2,3.76,3.76,0,0,1,1.63-1.32,6,6,0,0,1,2.4-.46,5.18,5.18,0,0,1,3.29,1,3.62,3.62,0,0,1,1.4,2.74h-2.89a1.45,1.45,0,0,0-.57-1.11,2.13,2.13,0,0,0-1.41-.44,1.81,1.81,0,0,0-1.23.39,1.41,1.41,0,0,0-.46,1.14,1.18,1.18,0,0,0,.34.88,2.83,2.83,0,0,0,.87.56c.34.14.83.31,1.45.5a15.37,15.37,0,0,1,2.08.75,3.73,3.73,0,0,1,1.39,1.12,3.12,3.12,0,0,1,.58,2,3.72,3.72,0,0,1-.54,1.95,3.94,3.94,0,0,1-1.6,1.44,5.51,5.51,0,0,1-2.49.53A6.29,6.29,0,0,1,236.12,254.29Z" /><path fill="#231f20" d="M263.85,241.54v13.09h-2.63v-5.57h-5.6v5.57H253V241.54h2.63v5.38h5.6v-5.38Z" />
      <path fill="#231f20" d="M276.56,241.54v13.09h-2.62V241.54Z" /><path fill="#231f20" d="M297.85,254.63h-2.63l-5.94-9v9h-2.63V241.52h2.63l5.94,9v-9h2.63Z" /><path fill="#231f20" d="M317.05,245.48a3.07,3.07,0,0,0-1.24-1.26,3.73,3.73,0,0,0-1.84-.43,4,4,0,0,0-2.06.52,3.65,3.65,0,0,0-1.4,1.51,4.73,4.73,0,0,0-.51,2.25,4.85,4.85,0,0,0,.51,2.28,3.72,3.72,0,0,0,1.44,1.5,4.22,4.22,0,0,0,2.14.53,3.68,3.68,0,0,0,2.45-.8,3.79,3.79,0,0,0,1.26-2.22h-4.5v-2h7.09v2.29a6.14,6.14,0,0,1-1.13,2.53A6.24,6.24,0,0,1,317,254a6.53,6.53,0,0,1-3.05.7,6.89,6.89,0,0,1-3.42-.85,6.21,6.21,0,0,1-2.4-2.37,7.32,7.32,0,0,1,0-6.92,6.24,6.24,0,0,1,2.4-2.38,6.94,6.94,0,0,1,3.4-.85,6.82,6.82,0,0,1,3.85,1.08,5.49,5.49,0,0,1,2.25,3Z" /><path fill="#414042" d="M29.35,212.87h2.87V187H29.35v-9.44H55.77q7.2,0,11.23,2.93a9.77,9.77,0,0,1,4,8.41,9.89,9.89,0,0,1-1.61,5.73,11.17,11.17,0,0,1-3.77,3.51,14.4,14.4,0,0,1-4.18,1.58v.58a15.83,15.83,0,0,1,7.37,2.59q3.28,2.19,3.28,7.83,0,6-4.54,8.84t-11.81,2.79H29.35ZM51.46,196.4a3.29,3.29,0,0,0,2.5-.89,4,4,0,0,0,.84-2.79v-2.08a4,4,0,0,0-.84-2.79,3.29,3.29,0,0,0-2.5-.89H48.35v9.44Zm.4,16.47a3.27,3.27,0,0,0,2.5-.89,4,4,0,0,0,.84-2.8v-2.53a3.94,3.94,0,0,0-.84-2.79,3.24,3.24,0,0,0-2.5-.9H48.35v9.91Z" />
      <path fill="#414042" d="M80.39,219.14q-4.92-4-4.93-12.89V187H72.59v-9.44h21.3V187h-2.3v22.22a4,4,0,0,0,.83,2.8,4,4,0,0,0,5,0,4,4,0,0,0,.84-2.8V187H96v-9.44h19.75V187h-2.88v19.29q0,8.92-4.78,12.89t-13.7,4Q85.31,223.12,80.39,219.14Z" /><path fill="#414042" d="M124,222.17a35.63,35.63,0,0,1-7.57-2.16v-11.4h12.66a3.54,3.54,0,0,0,.81,2.59,4.14,4.14,0,0,0,2.94.8,4.62,4.62,0,0,0,2.56-.54,1.88,1.88,0,0,0,.78-1.64,2.06,2.06,0,0,0-.75-1.59,8.71,8.71,0,0,0-2.59-1.35l-5-1.73q-6.39-2.42-9.19-5.7c-1.86-2.18-2.79-5.14-2.79-8.86q0-6.79,4.46-10.34t14.89-3.54a42.22,42.22,0,0,1,9.41,1,23.62,23.62,0,0,1,7.11,2.67V190.7H140.3q0-2.88-3.57-2.88a5.14,5.14,0,0,0-2.45.43,1.64,1.64,0,0,0-.72,1.53,1.8,1.8,0,0,0,.83,1.55,11.26,11.26,0,0,0,2.74,1.21l5,1.73q6.11,2.07,8.89,5.38a13,13,0,0,1,2.8,8.67q0,7.31-4.93,11.05t-14.42,3.75A55.4,55.4,0,0,1,124,222.17Z" /><path fill="#414042" d="M197.24,191.45h-9.9V187h-3.69v25.91h4v9.44H163.5v-9.44h4V187h-3.69v4.49h-9.9V177.52h43.3Z" /><path fill="#414042" d="M198.05,222.31v-9.44h2.87V187h-2.87v-9.44h38.86V194.9H224V187h-7v9.1h5.06v6.56h-5.06v10.25h7v-9.1h12.9v18.54Z" /><path fill="#414042" d="M238,212.87h2.87V187H238v-9.44h26.42c4.95,0,8.74,1,11.34,3s3.92,5.33,3.92,9.9a13.71,13.71,0,0,1-1.55,6.68,8.31,8.31,0,0,1-4.44,3.91l.06.58q5.52.87,5.53,6.79v4.44h2.7v9.44H263.68V208.78a7,7,0,0,0-.66-3.4,3.31,3.31,0,0,0-2.07-1.55,15.32,15.32,0,0,0-4-.4v9.44h3.8v9.44H238Zm22.1-16.47a3.29,3.29,0,0,0,2.51-.89,4,4,0,0,0,.83-2.79v-2.08a4,4,0,0,0-.83-2.79,3.29,3.29,0,0,0-2.51-.89H257v9.44Z" />
      <path fill="#414042" d="M290.25,222.17a35.81,35.81,0,0,1-7.57-2.16v-11.4h12.67a3.59,3.59,0,0,0,.8,2.59,4.14,4.14,0,0,0,2.94.8,4.62,4.62,0,0,0,2.56-.54,1.88,1.88,0,0,0,.78-1.64,2.06,2.06,0,0,0-.75-1.59,8.71,8.71,0,0,0-2.59-1.35l-4.95-1.73q-6.39-2.42-9.18-5.7c-1.87-2.18-2.8-5.14-2.8-8.86q0-6.79,4.46-10.34t14.89-3.54a42.16,42.16,0,0,1,9.41,1,23.62,23.62,0,0,1,7.11,2.67V190.7H306.52q0-2.88-3.57-2.88a5.17,5.17,0,0,0-2.45.43,1.64,1.64,0,0,0-.72,1.53,1.78,1.78,0,0,0,.84,1.55,11,11,0,0,0,2.73,1.21l5,1.73q6.11,2.07,8.9,5.38a13,13,0,0,1,2.79,8.67q0,7.31-4.93,11.05t-14.42,3.75A55.47,55.47,0,0,1,290.25,222.17Z" /><polygon fill="#231f20" points="335.45 236.93 14.12 236.93 14.12 176.88 21.12 176.88 21.12 229.93 328.45 229.93 328.45 168.93 223.08 168.93 223.08 161.93 335.45 161.93 335.45 236.93" /><path fill="#231f20" d="M217.31,118.85l-19.9-20.93-22.89.14-21,20.79h27.57l14.43-14.42,14.43,14.42Zm-97,0h25.24l31.16-30.09,6.8,6.74,7.56,0L178.62,83.09l-1.9-1.89-16.1.08h-19.2l-26.79,22.65,25.21-25.41.44-.38h18.06L147.68,66.27l-40.81.17V59.87l-17-5.1-8.5,5.1v19l-.65.19L79.18,66.55l-18.31.07L9.58,118.85h8.86L60.87,76.08l38.38,42.77Z" /><path fill="#231f20" d="M62,112h7v7H62Zm-2.43,0v7h-7v-7Zm0-2.42h-7v-7h7Zm9.4,0H62v-7h7Z" />
      <path fill="#231f20" d="M196.36,117.27h1.69V119h-1.69Zm-.58,0V119H194.1v-1.68Zm0-.59H194.1V115h1.68Zm2.27,0h-1.69V115h1.69Z" /><polygon fill="currentColor" points="124.19 62.07 124.19 11.88 152.58 0 152.58 65.37 146.39 61.99 124.19 62.07" /><polygon fill="currentColor" points="158.85 2.97 172.72 13.87 172.72 76.93 168.76 76.93 168.76 15.52 158.85 2.97" /><polygon fill="currentColor" points="172.06 24.1 193.52 15.19 193.52 91.12 179.98 76.92 170.74 76.92 172.06 24.1" /><polygon fill="currentColor" points="198.14 17.66 215.47 32.02 215.52 112.48 212.21 108.76 212.46 34.63 198.14 17.66" /><polygon fill="currentColor" points="214.96 100.76 235.71 100.76 249.21 133.51 309.46 133.51 325.46 156.76 223.21 156.76 223.21 121.09 214.3 111.1 214.96 100.76" /><path fill="currentColor" d="M282.08,45.45v1.93h2.61V71.6h1.24c.28-.41,12.8.14,12.8.14s5.5,2.2,6.33,2.47,5.64-3,5.64-3V68.71H299.83l-.14-15.28s1.52-4.54-.27-6-1.65-2.61-1.65-2.61,1.65-3.45-.42-4.41-2.75.28-3,1a5.13,5.13,0,0,0-.14,2.48,9.7,9.7,0,0,1,.14,1.79s-.28,1.38-.69,1.38-5.37,1.1-5.37,1.1l-1.65-1-.55-1.65ZM289,52.2l-.83,1.1v1.51l-.41,1.24-1.24-.41V51l2.48-.14Zm1.38-2.48,2.2-.41V52.2h-2.2Zm-4.13,9.63,3,.14v3.44h-3Z" /><polygon fill="currentColor" points="309.53 70.37 310.63 70.35 310.35 54.79 299.36 50.04 298.93 51.05 309.26 55.52 309.53 70.37" />
      <rect fill="currentColor" x="298.46" y="56.05" width="11.7" height="1.1" /><rect fill="currentColor" x="304.51" y="56.6" width="1.1" height="13.76" /><rect fill="currentColor" x="302.45" y="56.32" width="1.1" height="13.9" /><path fill="currentColor" d="M298.61,65.27l12-.28a.55.55,0,0,0,.23-1l-12.66-6.19-.49,1,10.63,5.19-9.71.23Z" /><path fill="currentColor" d="M285,68.71l-50.09,68.81,7.43,12.1,5.78-9.35,6.61-13.76,4.4-2.76,1.1-6.88,28.9-45.68ZM277.54,87l-27.66,42.34a.52.52,0,0,1-.42.22.48.48,0,0,1-.28-.08.5.5,0,0,1-.14-.69L276.7,86.49a.51.51,0,0,1,.7-.15A.5.5,0,0,1,277.54,87Z" /><path fill="currentColor" d="M216,39.09V51.64l66.12-4.26V45.45ZM217.12,42V40.93l55,4.58Z" /><polygon fill="currentColor" points="159.77 17.95 156.14 15.7 156.14 10.2 159.77 12.45 159.77 17.95" /><polygon fill="currentColor" points="165.64 21.26 162.02 19.01 162.02 13.51 165.64 15.76 165.64 21.26" /><polygon fill="currentColor" points="159.52 29.57 155.89 27.32 155.89 21.82 159.52 24.07 159.52 29.57" /><polygon fill="currentColor" points="165.39 32.88 161.77 30.64 161.77 25.14 165.39 27.39 165.39 32.88" /><polygon fill="currentColor" points="159.85 41.07 156.23 38.82 156.23 33.32 159.85 35.57 159.85 41.07" /><polygon fill="currentColor" points="165.94 68.64 162.31 66.39 162.31 60.88 165.94 63.13 165.94 68.64" /><polygon fill="currentColor" points="165.73 44.38 162.1 42.13 162.1 36.63 165.73 38.88 165.73 44.38" />
      <polygon fill="currentColor" points="201.94 67.45 198.31 65.2 198.31 59.7 201.94 61.95 201.94 67.45" /><polygon fill="currentColor" points="207.81 70.76 204.19 68.51 204.19 63.01 207.81 65.26 207.81 70.76" /><polygon fill="currentColor" points="202.06 56.82 198.44 54.57 198.44 49.07 202.06 51.32 202.06 56.82" /><polygon fill="currentColor" points="202.6 40.13 198.98 37.88 198.98 32.38 202.6 34.63 202.6 40.13" /><polygon fill="currentColor" points="207.94 60.13 204.31 57.88 204.31 52.38 207.94 54.63 207.94 60.13" /><polygon fill="currentColor" points="201.69 79.07 198.06 76.82 198.06 71.32 201.69 73.57 201.69 79.07" /><polygon fill="currentColor" points="207.56 82.39 203.94 80.14 203.94 74.64 207.56 76.89 207.56 82.39" /><polygon fill="currentColor" points="202.02 90.57 198.39 88.32 198.39 82.82 202.02 85.07 202.02 90.57" /><polygon fill="currentColor" points="207.89 93.89 204.27 91.64 204.27 86.14 207.89 88.39 207.89 93.89" /></g></g></svg>




)

export default Logo


